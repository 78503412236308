import React, { FC } from "react";
import cn from "classnames";

import classes from "./Skeleton.module.scss";

type Props = {
	size?: "default" | "large" | "small";
	className?: string;
	card?: boolean;
};
const Skeleton: FC<Props> = ({ size = "default", className, card }) => {
	const classnames = cn(classes.skeleton, card && classes.card, size && classes[size], className && className);

	return <div className={classnames} />;
};

export default Skeleton;
